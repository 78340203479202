html {
    overflow-x: hidden;
}
body {
    font-family: 'Montserrat', sans-serif;
}
a { 
    color: inherit; 
    &:hover { text-decoration: none; opacity: 0.8; color: inherit;}
    &.orange-link { color: $orange; text-transform: uppercase;}
    &:focus { border: inherit; text-decoration: inherit;}
}

.container {
    max-width: 940px;
}
@media screen and (max-width: 780px) {
 .container { max-width: 90%; }
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.text-center {
    text-align: center;
}


