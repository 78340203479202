header {
    padding-top: 30px;
    .container { position: relative;}
    .fa { color: $greyicons; }

    @media screen and (max-width: 780px) {
        padding-top: 10px;
    }
    
    #search-box {
        display: flex; align-items: center;
        justify-content: flex-end;
        a{
            margin-left: 15px;
        }
    }
    .logo { display: inline-block;
    
        img{
            @media screen and (max-width: 442px) {
                width: 150px;
            }
        }
    }

    #menu{
        display: flex;
        align-items: center; 
        justify-content: flex-end;
        @media screen and (min-width: 781px) {
            display: flex!important;
        }
        @media screen and (max-width: 780px) {
            display: none;
        }
    }
    #divisions{
        display: flex;
        align-items: center; 
        justify-content: flex-end;
        margin-top: 10px; 
        @media screen and (max-width: 780px) {
            display: none;
        }
        li{
            margin-right: 10px;
            a{
                color: #aaa;
                &:hover,&:focus{
                    color: #fa8730;
                }
            }
            a:before{
                content: '/';
                margin-right: 10px;
                color: #aaa;
            }

            &:first-child{
                a:before{
                    display: none;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    ul { 
        list-style: none; margin-top: 10px;
        @media screen and (max-width: 780px) {
            margin-top: 0;
            margin-bottom: 0;
        }

        li { 
            float: left; margin-right: 19px; padding: 0; position: relative;
            &:last-child { margin-right: 0px; }
            &:hover > a {
                    color: #000; opacity: 1;
            }
            a { 
                display: block; font-weight: 700; text-transform: uppercase; font-size: 12px; color: #8F8F8F;                
                &.active {
                    color: #000;
                }
            }
            > ul { 
                z-index: 300; transform: translateX(-50%);  max-height: 0; overflow: hidden; transition: all 0.1s;
                position: absolute; left: 50%; padding: 0; margin: 0; text-align: center; background: $grey;
                @media screen and (max-width: 780px) {
                    left: 0;transform: translateX(0%);
                }
                li {
                    margin: 0;
                    text-align: center; display: block; width: 186px;
                    &:after { 
                        content: " ";
                        border-bottom: 1px solid $darkgreytext;
                        width: 16px;
                        position: absolute;
                        left: 50%;
                        margin-left: -8px;
                    }
                    &:last-child:after {
                        border-bottom: none;
                    }
                    a {
                        display: block; padding: 17px 8px; color: $darkgreytext;
                    }
                }
                &:hover { display: block;}
            }
            &:hover > ul {
                display: block; max-height: 600px; transition: all 1s;
            }
        }
    }
    
    .mobile-nav-toggle {
        width: 60px; height: 60px; display: none; position: absolute; right: 0; top: 18px;
        span {
            -webkit-transition: 0.5s ease-out;
            -moz-transition: 0.5s ease-out;
            -ms-transition: 0.5s ease-out;
            -o-transition: 0.5s ease-out;
            transition: 0.5s ease-out;
            display: block;
            width: 100%;
            height: 8px;
            background: $grey;
            margin: 0px auto 10px auto;
        }
        &.active {
            span:nth-child(2) { width: 50%;}
            span:nth-child(3) { width: 25%; }
        }
    }
    @media screen and (max-width: 780px) {
        #search-box { display: none; }
        .pull-right {
            float: none !important;
            position: relative;
            z-index: 200;
            width: 100%;
        }
        .pull-left {
            float: none !important;
        }
        ul { 
            width: 100%; background: $grey; padding: 0; display: none;
            li { 
                width: 100%; text-align: center; float: none;
                a { padding: 17px 0px; }
                &:after { 
                    content: " ";
                    border-bottom: 1px solid $darkgreytext;
                    width: 16px;
                    position: absolute;
                    left: 50%;
                    margin-left: -8px;
                }
                &:last-child:after {
                    border-bottom: 0;
                }
                &:last-child:after {
                    border-bottom: none;
                }
                > ul { 
                    position: relative; transform: translateX(0%); background: $greyicons; display: block;
                    li { 
                        width: 100%; 
                        a { color: #fff;} 
                    }
                }
            }
        }
        .mobile-nav-toggle { display: block;}
        .logo { display: block; text-align: left;}
        
    }
    @media screen and (max-width: 442px) {
        .mobile-nav-toggle { 
            font-size: 11px; top: 18px; margin-top: 0; width: 40px; height: 40px;
            span { height: 6px; margin-bottom: 6px;}
        }
        .logo { display: block; }
    }
    
}

footer {
    background: $grey; padding: 39px 0px 56px 0px; color: $darkgreytext;
    .row > div {
        max-width: 50%;
        &:nth-child(2) { text-align: right;}
    }
    .footer-icons {
        img:nth-child(1) { margin-right: 14px;}
        img:nth-child(2) { margin-right: 7px;}
        img:nth-child(3) { margin-right: 6px;}
    }
    .footer-text {
        font-size: 10px; font-weight: 700;
    }
    @media screen and (max-width: 992px) {
        .footer-text {
            width: 100% !important;
            display: block;
            float: none !important;
            text-align: center  !important;
            max-width: 100% !important;
        }
        .footer-icons {
            max-width: 100% !important;
            float: none !important;
            text-align: center;
            width: 100% !important;
            margin-bottom: 24px;
            img { margin-bottom: 10px; }
        }
    }
}



section#carousel {
    .content { background: $black; padding: 31px 0px 0px 0px; margin-top: 30px;
        @media screen and (max-width: 780px) {
            margin-top: 10px;
        }
    }
    &.no-slides {
        margin-bottom: 32px;
        .container { padding: 0;
        }
    }
    .pager-container {
        background: #fff;
        .pager { 
            padding: 7px 0px; margin-bottom: 7px; text-align: left;
            i { color: $lightgrey; font-size: 12px;}
            i.active { color: $orange;}
        }
    }
    #carousel-wrapper {
        &:after {
            cursor: pointer;
            content: "";
            bottom: 28px;
            position: absolute;
            right: -60px;
            background-image: url('images/arrow-right.png');
            background-repeat: no-repeat;
            width: 17px;
            height: 44px;
            z-index: 100;
        }
        &:before {
            content: "";
            bottom: 28px;
            position: absolute;
            left: -60px;
            background-image: url('images/arrow-left.png');
            background-repeat: no-repeat;
            width: 17px;
            height: 44px;
            z-index: 100;
            cursor: pointer;
        } 
    }
    .slide .left {
        width: 65.8%; 
    }
    @media screen and (max-width: 780px) {
        .slide .left {
            width: 100%;
        }
        h1 {
            font-size: 14.1vw !important; 
        }
    }
    .page-title {
        text-transform: uppercase;
    }
}

.content-box {
    padding: 32px 0px; 
    &.border-top {
        border-top: 8px solid $grey;
    }
    .headline { font-weight: normal; font-size: 21px; margin-bottom: 10px; display: block;}
    &.map-box {
        img { max-width: 100%; }
    }
}




#page {
    .home-header{
        height: calc(100vh - 148px);
        background-color: #fa8730;
        margin-top: 30px;
        margin-bottom: 35px;  
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 0;
        position: relative;
        .bottom-left{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 150px;
            @media screen and (max-width: 1199px) {
                width: 100px;
            }
            @media screen and (max-width: 780px) {
                display: none;
            }
           
        }
        .top-right{
            position: absolute;
            top: 0;
            right: 0;
            width: 320px;
            @media screen and (max-width: 1199px) {
                width: 250px;
            }
            @media screen and (max-width: 991px) {
                width: 200px;
            }
            @media screen and (max-width: 780px) {
                display: none;
            }
        }
        
        @media screen and (max-width: 780px) {
            height: auto;
            margin-top: 10px;
        }
        
        .inner{
            padding: 100px 0;
            @media screen and (max-width: 767px) {
                padding: 80px 0;
            }
            @media screen and (max-width: 575px) {
                padding: 50px 0;
            }
            .text{
                max-width: 80%;
                @media screen and (max-width: 767px) {
                    
                    max-width: 100%;
                }
              
            }
            h1{
                
                margin: 0 0 0 0;
                display: block;
                border-top: 8px solid #fff;
                color: #fff;
                font-size: 50px;
                text-indent: -3px;
                padding-top: 10px;
                font-weight: 700;
                @media screen and (max-width: 991px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 25px;
                }
  
                
            }
            p{
                margin-top: 10px;
                max-width: 70%;
                color: #fff;
                font-size: 20px;
                @media screen and (max-width: 991px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 17px;
                    max-width: 100%;
                }
                @media screen and (max-width: 575px) {
                    font-size: 14px;
                }
            }  

            .buttons{  
                margin-top: 20px;
                a{
                    display: inline-block;
                    background: #fff;
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #666;
                    @media screen and (max-width: 767px) {
                        font-size: 15px;
                    }
                    @media screen and (max-width: 575px) {
                        font-size: 14px;
                    }
                    &:hover, &:focus{
                        background: #E9501D;
                        color: #fff;
                        opacity: 1;
                    }
                }
            }

            
        }

        .arrow-down{
            position: absolute;
            bottom: 20px;
            animation: bounce 1s infinite alternate;
            left: 50%;
            transform: translateX(-50%);
            transition: 2s all ease-in-out;
            i{
                color: #fff;
            }
            @media screen and (max-width: 780px) {
                display: none;
            }
        }

     
        @keyframes bounce {
            from {
              transform: translateY(0px);
            }
            to {
              transform: translateY(-15px);
            }
          }
    }

    #home-news{
        h2{
            color: #E9501D;
            font-weight: 700;
            font-size: 30px;
            margin-top: 0;
            margin-bottom: 30px;
            text-align: center;
            @media screen and (max-width: 767px) {
                font-size: 25px;
            }
        }

        .more-news{
            display: inline-block;
            margin: 3px auto 60px;
            background: #E9501D;
            color: #fff;
            padding: 10px 20px;
            font-size: 20px;
            font-weight: bold;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            }
        }

        .row{
            display: flex;
            flex-wrap: wrap;
            &:before, &:after{
                display: flex;
            }
            >div{
                display: flex;
                align-items: stretch;
                .news-item{
                    margin-bottom: 30px;
                    .image{
                        background-color: #ccc;
                        background-size: cover;
                        background-position: center;
                        height: 150px;
                        @media screen and (max-width: 1199px) {
                            height: 200px;
                        }
                        @media screen and (max-width: 767px) {
                            height: 35vw;
                        }
                    }
                    h3{
                        margin: 10px 0px;
                        display: block;
                        font-size: 18px;
                        font-weight: bold;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                    p{
                        font-size: 13px;
                    }
                    a{
                        color: #fa8730;
                        text-transform: uppercase;
                        &:hover, &:focus{
                            text-decoration: none;
                             opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
    .col-left { 
        margin-right: 24px; width: 65.8%; float: left;
        p { margin-bottom: 19px;}
        .page-title { text-transform: uppercase; font-size: 21px; font-weight: 700; margin-bottom: 20px;}
        .headline { margin-bottom: 30px;}
        .image-widgets > div {
            width: 49%; margin-bottom: 12px;
            &:nth-child(1), &:nth-child(3) { margin-left: 0; }
        }
    }
    
    .col-right { float: right; width: 31%;}
    @media screen and (max-width: 780px) {
        .col-right, .col-left { width: 100%; float: none; }
    }
    .project-widgets {
        > div {
            &:nth-child(1) > div { margin-right: 16px; }
            &:nth-child(2) > div { margin-right: 8px; margin-left: 8px; }
            &:nth-child(3) > div { margin-left: 16px; }
        }
        .project-widget {          
            img { width: 100%;}
            strong { margin: 22px 0px 15px 0px; display: block; font-size: 18px; }
            p { margin: 15px 0px; }
            a { color: $orange; text-transform: uppercase; }
        }
        @media screen and (max-width: 780px) {
            > div {
                &:nth-child(1) > div { margin: 0; }
                &:nth-child(2) > div { margin: 0; }
                &:nth-child(3) > div { margin: 0; }
                border-bottom: 3px solid $grey; padding-bottom: 20px; margin-bottom: 20px;
                &:last-child { border-bottom: none; }
            }
        }
    }
    .news-article {
        img { width: 100%; margin-bottom: 20px;}
        border-bottom: 3px solid $grey; margin-bottom: 38px; padding-bottom: 38px; font-size: 14px;        
        .headline { font-weight: normal; font-size: 19px; text-transform: uppercase; margin-bottom: 20px;}
        span.date { font-size: 16px;}
        &:last-child { border-bottom: 0; padding-bottom: 10px; margin-bottom: 0px;}
        a { color: $orange; text-transform: uppercase; font-size: 13px; }
    }
    .news-archive-widget {        
        strong { display: block; padding: 5px 0px;}
        .headline { padding: 0;}
    }
    .news-widgets {
        .title { font-size: 21px; margin-bottom: 16px; display: block; font-weight: normal;}
        .news-widget {
            border-bottom: 3px solid $grey; margin-bottom: 38px; padding-bottom: 38px; font-size: 11px;
            .headline { font-weight: normal; font-size: 11px; text-transform: uppercase;}
            &:last-child { border-bottom: 0; padding-bottom: 10px; margin-bottom: 0px;}
            a { color: $orange; text-transform: uppercase; font-size: 13px; }
            
        }
    }
    .links-widgets {
        ul { 
            list-style: none; padding: 0; margin: 0;
            li {
                &.active { font-weight: 700;}
                a {
                    font-size: 20px; text-transform: uppercase; padding: 11px 0px; display: block;
                    &:focus {
                        border: initial;
                    }
                }
                border-bottom: 3px solid $grey;
                &:last-child { border-bottom: 0; }
            }
        }
    }
    .projects{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .project{
            padding: 0 15px;
            
            flex-basis: 50%;
            max-width: 50%;
            display: flex;
            align-items: stretch;
            @media(max-width: 650px){
                flex-basis: 100%;
                max-width: 100%;
            }
            .inner{
                margin-bottom: 30px;
                position: relative;
                display: block;
                flex-basis: 100%;
                max-width: 100%;
                overflow: hidden;
                .pic{
                    height: 250px;
                    width: 100%;
                    display: block;
                    background-size: cover;
                    background-position: center center;
                    transition: all 0.35s ease;
                }
                .overlay{
                    position: absolute;
                    @media(max-width: 767px){
                        position: relative;
                        opacity: 1;
                        visibility: visible;
                        height: 50%;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 100%;
                    text-align: center;
                    width: 100%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    padding: 40px 20px;
                    transition: all 0.35s ease;
                    color: #fff;
                    background: rgba(187, 58, 8, 0.8);
                    strong{
                        margin-bottom: 20px;
                        font-size: 20px;
                        transition: all 0.35s ease;
                        transform: translateY(-10px);
                        @media(max-width: 991px){
                            margin-bottom: 10px;
                            font-size: 18px;
                        }
                        @media(max-width: 767px){
                            transform: translateY(0);
                        }
                    }
                    p{
                        transition: all 0.35s ease;
                        transform: translateY(-10px);
                        @media(max-width: 767px){
                            transform: translateY(0);
                        }
                    }
                    span{
                        transition: all 0.35s ease;
                        transform: translateY(10px);
                        background: #fff;
                        padding: 4px 20px;
                        color: #fa8730;
                        text-transform: uppercase;
                        font-weight: bold;
                        margin-top: 30px;
                        @media(max-width: 991px){
                            margin-top: 20px;
                        }
                        @media(max-width: 767px){
                            transform: translateY(0);
                        }
                    }
                }
    
                &:hover,&:focus{
                    opacity: 1;
                    .pic{
                        transform: scale(1.1);
                        @media(max-width: 767px){
                            transform: scale(1);
                        }
                    }
                    .overlay{
                        opacity: 1;
                        visibility: visible;

                        strong, p, span{
                            transform: translateY(0px);
                        }
                    }
            }
            }
            
            
        }
    }
    .service-widgets {
        > div:nth-child(even) > div {
            margin-left: 12px;
        }
        > div:nth-child(odd) > div {
            margin-right: 12px;
        }
        > div { margin-bottom: 50px;}
        .service-widget {   
            img { width: 100%;}
            strong { margin: 22px 0px 15px 0px; display: block; font-size: 18px; text-transform: uppercase;}
            p { margin: 15px 0px; }
            a { color: $orange; text-transform: uppercase; }
        }
        @media screen and (max-width: 780px) {
            .service-widget {          
                height: auto;
            }
            > div:nth-child(even) > div {
                margin-left: 0px;
            }
            > div:nth-child(odd) > div {
                margin-right: 0px;
            }
        }
    }
    
    .image-widgets {
        .image-widget {
            width: 50%; float: left;
            img { width: 100%; }
            padding-bottom: 6px;
            &:nth-child(even) {
            padding-left: 3px;
            }
            &:nth-child(odd) {
                padding-right: 3px;
            }
        }
    }
    .contact-widget {
        table { width: 100%; }
        img { width: 100%; margin-bottom: 25px; }
        border-bottom: 3px solid $grey; padding-bottom: 40px; margin-bottom: 40px;
        &:last-child { border-bottom: 0; }
        h2 { margin: 0px 0px 10px 0px}
        @media screen and (max-width: 780px) {
            tr, th, td { display: block; text-align: left;}
            tr { margin-bottom: 10px; border-bottom: 1px solid $grey; padding-bottom: 10px;}
        }
    }
    .google-maps {
        position: relative;
        padding-bottom: 50%;
        height: 0;
        overflow: hidden;
        margin-bottom: 15px;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}

#page.about {
    
}


